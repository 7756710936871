import { gql, useQuery } from "@apollo/client";
import moment from "moment";

const GET_CONTACTS_QUERY = gql`
  query ($extId: String, $today: String, $nextBusinessDay: String, $settlementStartDate: String) {
    contacts(extId: $extId) {
      portfolios {
        portfolioReportToday: portfolioReport(
          reportDate: $today
          calculateExpectedAmountBasedOpenTradeOrders: true
          calculateExpectedAmountBasedUnsettledTransactions: true
          useLatestPositionsAmount: true
          adjustPositionsBasedOnOpenTradeOrders: false
        ) {
          accountItems {
            currencyId
            currency {
              securityCode
              __typename
            }
            accountName
            amountBeforeUnsettledTransactions
            expectedAmount
            amountAfterOpenTradeOrders
            balanceAccCurr
            buyOpenTradeOrder: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "4"
            ) {
              buyOpenTradeAmount: tradeAmount
              __typename
            }
            buyAcceptedTradeOrder: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "5"
            ) {
              buyAcceptedTradeAmount: tradeAmount
              __typename
            }
            buyExecutableTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "1"
            ) {
              buyExecutableTradeAmount: tradeAmount
              __typename
            }
            buySentToExecutionTradeOrder: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "10"
            ) {
              buySentToExecutionTradeAmount: tradeAmount
              __typename
            }
            buyInExecutionTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "6"
            ) {
              buyInExecutionTradeAmount: tradeAmount
              __typename
            }
            buyPartiallyExecutedTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "12"
            ) {
              buyPartiallyExecutedTradeAmount: tradeAmount
              __typename
            }
            buyExecutedMarketTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "7"
            ) {
              buyExecutedMarketTradeAmount: tradeAmount
              __typename
            }
            __typename
          }
          __typename
        }
        portfolioReportTomorrow: portfolioReport(
          reportDate: $nextBusinessDay
          calculateExpectedAmountBasedOpenTradeOrders: true
          calculateExpectedAmountBasedUnsettledTransactions: true
          useLatestPositionsAmount: true
          adjustPositionsBasedOnOpenTradeOrders: false
        ) {
          accountItems {
            currencyId
            accountName
            sellUnsettledTransactions: transactions(
              aggregationMode: 2
              settlementStartDate: $settlementStartDate
              typeCodes: ["S", "RED"]
            ) {
              sellUnsettledTradeAmount: tradeAmount
              __typename
            }
            __typename
          }
          __typename
        }
      }
      name
      externalId
      contactId
      __typename
    }
  }
`;

interface AccountItem {
  accountName: string;
  currencyId?: number | undefined;
  currency: {
    securityCode: string;
  };
  amountBeforeUnsettledTransactions?: number | undefined;
  expectedAmount?: number | undefined;
  amountAfterOpenTradeOrders?: number | undefined;
  balanceAccCurr: number;
  buyOpenTradeOrder: { buyOpenTradeAmount: number }[];
  buyAcceptedTradeOrder: { buyAcceptedTradeAmount: number }[];
  buyExecutableTradeOrders: { buyExecutableTradeAmount: number }[];
  buySentToExecutionTradeOrder: { buySentToExecutionTradeAmount: number }[];
  buyInExecutionTradeOrders: { buyInExecutionTradeAmount: number }[];
  buyPartiallyExecutedTradeOrders: { buyPartiallyExecutedTradeAmount: number }[];
  buyExecutedMarketTradeOrders: { buyExecutedMarketTradeAmount: number }[];
}

interface TomorowAccountItem {
  accountName: string;
  currencyId?: number | undefined;
  sellUnsettledTransactions: { sellUnsettledTradeAmount: number }[];
}

interface Contact {
  portfolios: Portfolios[];
}

interface Portfolios {
  portfolioReportToday: {
    accountItems: AccountItem[];
  }
  portfolioReportTomorrow: {
    accountItems: TomorowAccountItem[];
  }
}

interface ContactsData {
  contacts: Contact[];
}

export interface Balance {
  trading: { currency: string, amount: number }[];
  withdrawal: { currency: string, amount: number }[];
}
interface Balances { 
  [key: string]: Balance
}

export const useGetAvailableBalancesExperimental = (
  extId: string | undefined
) => {

  const addBusinessDays = (date: Date, daysToAdd: number) => {
    const WEEKEND = [moment().day("Saturday").weekday(), moment().day("Sunday").weekday()]
    let daysAdded = 0;
    let momentDate = moment(new Date(date));
    while (daysAdded < daysToAdd) {
      momentDate = momentDate.add(1, 'days');
      if (!WEEKEND.includes(momentDate.weekday())) {
        daysAdded++
      }
    }

    return momentDate;
  }

  const currentDate = new Date();
  const nextBusinessDay = addBusinessDays(currentDate, 1);
  const settlementStartDate = addBusinessDays(currentDate, 2);

  const variables: { extId: string | undefined; today: string; nextBusinessDay: string; settlementStartDate: string; } = {
    extId,
    today: currentDate.toISOString().split('T')[0],
    nextBusinessDay: nextBusinessDay.format('YYYY-MM-DD'),
    settlementStartDate: settlementStartDate.format('YYYY-MM-DD'),
  };

  const { loading, error, data } = useQuery<ContactsData>(GET_CONTACTS_QUERY, extId ? {
    variables: variables,
  } : { skip: true });

  const balances:Balances = {}

  data?.contacts[0]?.portfolios.forEach((portfolio: Portfolios) => {
    portfolio?.portfolioReportToday?.accountItems.forEach((item: AccountItem) => {
      const accountNumber = item.accountName.split('-')[0];
      const currency = item.currency.securityCode;
      
      const tradingAmount = item.balanceAccCurr - (
        (item.buyOpenTradeOrder[0]?.buyOpenTradeAmount || 0) 
        + (item.buyExecutableTradeOrders[0]?.buyExecutableTradeAmount || 0)
        + (item.buySentToExecutionTradeOrder[0]?.buySentToExecutionTradeAmount || 0)
        + (item.buyInExecutionTradeOrders[0]?.buyInExecutionTradeAmount || 0)
        + (item.buyPartiallyExecutedTradeOrders[0]?.buyPartiallyExecutedTradeAmount || 0)
        + (item.buyExecutedMarketTradeOrders[0]?.buyExecutedMarketTradeAmount || 0)
      );

      const tomorrowItem = portfolio?.portfolioReportTomorrow?.accountItems.find((tomorowItem) => tomorowItem.currencyId === item.currencyId);

      const withdrawalAmount = tradingAmount - (tomorrowItem?.sellUnsettledTransactions[0]?.sellUnsettledTradeAmount || 0);

      if (!balances[accountNumber]) {
        balances[accountNumber] = { 
          trading: [],
          withdrawal: [] 
        };
      }

      balances[accountNumber].trading.push({ currency, amount: tradingAmount });
      balances[accountNumber].withdrawal.push({ currency, amount: withdrawalAmount });
    });
  });

  return {
    loading,
    error,
    data,
    balances,
  };
};


